import React, { useEffect, useRef, useState } from "react";
import { ParseInventToSelectItem } from "../helper/ParseInventToSelectItem";
import imgBookingLeft from "../../images/imageBookingLeft.jpg";
import imgBookingRight from "../../images/imageBookingRight.jpg";
import { v4 as uuidv4 } from "uuid";
import ButtonBooking from "../Topmenu/ButtonBooking";

type IProps = {
  hideBookingBox: () => void;
};

const Reserver: React.FC<IProps> = ({ hideBookingBox }) => {
  const [selectCanotState, setSelectCanotState] = useState<string[]>([]);
  const nbCanot = 10;

  useEffect(() => {
    setSelectCanotState(ParseInventToSelectItem("Canot(s)", nbCanot));
  }, []);
  useEffect(() => {}, [selectCanotState]);

  const activityInputRef = useRef<HTMLSelectElement>(null);

  // ***-------------------DateState-----------------------------------***
  const dateInputRef = useRef<HTMLInputElement>(null);
  const [dateState, setDateStare] = useState<string>("");

  const updateDateState = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDateStare(event.target.value);
  };

  useEffect(() => {}, [dateState]);
  // FIN-------------------DateState-----------------------------------***

  const durationInputRef = useRef<HTMLSelectElement>(null);
  const arrivalTimeInputRef = useRef<HTMLSelectElement>(null);
  const firstNameInputRef = useRef<HTMLInputElement>(null);
  const lastNameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const phoneInputRef = useRef<HTMLInputElement>(null);
  const numberOfAdultsInputRef = useRef<HTMLSelectElement>(null);
  const numberOfKidsInputRef = useRef<HTMLSelectElement>(null);
  const yourteChoiceInputRef = useRef<HTMLSelectElement>(null);
  const numberOfKayaksInputRef = useRef<HTMLSelectElement>(null);
  const numberOfSupInputRef = useRef<HTMLSelectElement>(null);
  const totalPriceInputRef = useRef<HTMLHeadingElement>(null);
  const paymentMethodInputRef = useRef<HTMLSelectElement>(null);
  const codeInputRef = useRef<HTMLInputElement>(null);
  const cardNumberInputRef = useRef<HTMLInputElement>(null);
  const cvvInputRef = useRef<HTMLInputElement>(null);
  const cardMonthInputRef = useRef<HTMLSelectElement>(null);
  const cardYearInputRef = useRef<HTMLSelectElement>(null);

  return (
    <div>
      <div className="absolute">
        <div className="flex bg-aqs-blue-300 bg-opacity-90 text-black">
          <div className="flex flex-col justify-center">
            <img id="logoTopMenu" src={imgBookingLeft} />
          </div>
          <div className="flex px-8 bg-no-repeat flex-col border-2 border-l-aqs-blue-900">
            {/* // ***-------------------First line-----------------------------------*** */}
            <div className="flex flex-row flex-wrap justify-start">
              <div className="flex m-3">
                <select
                  className="sm:w-auto w-[300px] p-3 rounded"
                  name="activity"
                  id="activity"
                  ref={activityInputRef}>
                  <option value="titre">Choissisez votre activitée</option>
                  <option value="eauCalme4H">-Eau calme-4h-15km 49$/pers.</option>
                  <option value="eauCalme7H">-Eau calme-7h-25km 69$/pers.</option>
                  <option value="canot2J">-Canot-Camping-98$/PERS.</option>
                  <option value="canot3J">-Canot-Camping-139$/PERS.</option>
                </select>
              </div>
              <div className="flex m-3">
                <input
                  type="date"
                  value={dateState}
                  onChange={updateDateState}
                  id="date"
                  name="date"
                  ref={dateInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded"></input>
              </div>
              <div className="flex m-3">
                <select
                  name="duration"
                  id="duration"
                  ref={durationInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="titre">Durée</option>
                  <option value="1jour">1 jour</option>
                  <option value="2jour">2 jours</option>
                  <option value="3jour">3 jours</option>
                </select>
              </div>
              <div className="flex m-3">
                <select
                  name="arrivalTime"
                  id="arrivalTime"
                  ref={arrivalTimeInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="hour">Heure de l&#39;activité</option>
                  <option value="9heure">9h</option>
                  <option value="10heure">10h</option>
                  <option value="11heure">11h</option>
                </select>
              </div>
            </div>
            {/* // END-------------------First line-----------------------------------*** */}

            {/* // ***-------------------Second line-----------------------------------*** */}
            <div className="flex flex-row flex-wrap justify-start">
              <div className="flex m-3">
                <input
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="Votre prénom"
                  ref={firstNameInputRef}
                  size={10}
                  className="sm:w-auto w-[300px] p-3 rounded"></input>
              </div>
              <div className="flex m-3">
                <input
                  type="text"
                  id="lname"
                  name="lname"
                  placeholder="Votre nom"
                  className="sm:w-auto w-[300px] p-3 rounded"
                  ref={lastNameInputRef}
                  size={10}></input>
              </div>
              <div className="flex m-3">
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Votre courriel"
                  className="sm:w-auto w-[300px] p-3 rounded"
                  ref={emailInputRef}></input>
              </div>
              <div className="flex m-3">
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Votre téléphone"
                  className="sm:w-auto w-[300px] p-3 rounded"
                  size={8}
                  ref={phoneInputRef}></input>
              </div>
            </div>
            {/* // END-------------------Second line-----------------------------------*** */}

            {/* // ***-------------------Third line-----------------------------------*** */}
            <div className="flex flex-row flex-wrap justify-start">
              <div className="flex m-3">
                <select
                  name="numbersOfAdults"
                  id="numbersOfAdults"
                  ref={numberOfAdultsInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="adults">Adultes (13 ANS ET +)</option>
                  <option value="oneAdult">1 Adul.</option>
                  <option value="twoAdults">2 Adul.</option>
                  <option value="threeAdults">3 Adul.</option>
                </select>
              </div>
              <div className="flex m-3">
                <select
                  name="numbersOfKids"
                  id="numberOfKids"
                  ref={numberOfKidsInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="kids">Enfants (12 ANS ET -)</option>
                  <option value="zeroKid">0 Enf.</option>
                  <option value="oneKid">1 Enf.</option>
                  <option value="twoKids">2 Enf.</option>
                </select>
              </div>
              <div className="flex m-3">
                <select
                  name="yourteChoice"
                  id="yourteChoice"
                  ref={yourteChoiceInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="yourte">Yourte</option>
                  <option value="zeroYourte">Aucune</option>
                  <option value="oneYourte">Yourte Perchée</option>
                  <option value="twoYourtes">Yourte Traditionelle</option>
                </select>
              </div>
              <div className="flex m-3">
                <select name="test" id="test">
                  <option value="0">Canot(s)</option>
                  {selectCanotState.map((data, index) => (
                    <option key={uuidv4()} value={index}>
                      {data}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex justify-center m-4">
                <select name="numberOfKayaks" id="numberOfKayaks" ref={numberOfKayaksInputRef}>
                  <option value="kayaks">Nb de Kayaks</option>
                  <option value="zeroKayak">0 kayak</option>
                  <option value="oneKayak">1 kayak</option>
                  <option value="twoKayaks">2 kayaks</option>
                </select>
              </div>
              <div className="flex m-3">
                <select
                  name="numberOfSup"
                  id="numberOfSup"
                  ref={numberOfSupInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="sup">NB de SUP</option>
                  <option value="zeroSup">0 SUP</option>
                  <option value="oneSup">1 SUP</option>
                  <option value="twoSup">2 SUP</option>
                </select>
              </div>
            </div>

            {/* // END-------------------Third line-----------------------------------*** */}

            {/* // ***-------------------Forth line-----------------------------------*** */}
            <div className="flex justify-around">
              <div className="flex justify-center m-3 text-black text-4xl">
                <h6 ref={totalPriceInputRef}>0.00$</h6>
              </div>
            </div>
            {/* // END-------------------Forth line-----------------------------------*** */}

            {/* // ***-------------------Fifth line-----------------------------------*** */}
            <div className="flex flex-row flex-wrap justify-start">
              <div className="flex m-3">
                <select
                  name="paymentMethod"
                  id="paymentMethod"
                  ref={paymentMethodInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="payment">Mode de Paiement</option>
                  <option value="visa">Visa</option>
                  <option value="masterCard">MasterCard</option>
                </select>
              </div>
              <div className="flex m-3">
                <input
                  type="text"
                  id="code"
                  name="code"
                  placeholder="DEALCODE OU NOTES"
                  ref={codeInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded"
                  size={5}></input>
              </div>
              <div className="flex m-3">
                <input
                  type="cardNumber"
                  id="cardNumber"
                  name="cardNumber"
                  placeholder="NO. DE CARTE DE CRÉDIT"
                  ref={cardNumberInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded"
                  size={11}></input>
              </div>
              <div className="flex m-3">
                <input
                  type="text"
                  id="cvv"
                  size={4}
                  name="cvv"
                  placeholder="CVV"
                  ref={cvvInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded"></input>
              </div>
              <div className="flex m-3">
                <select
                  name="month"
                  id="month"
                  ref={cardMonthInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="mm">MM</option>
                  <option value="january">01</option>
                  <option value="february">02</option>
                  <option value="march">03</option>
                </select>
              </div>
              <div className="flex m-3">
                <select
                  name="year"
                  id="year"
                  ref={cardYearInputRef}
                  className="sm:w-auto w-[300px] p-3 rounded">
                  <option value="aa">AA</option>
                  <option value="2021">21</option>
                  <option value="2022">22</option>
                  <option value="2023">23</option>
                </select>
              </div>
            </div>

            <div
              className="absolute lg:right-[17%] sm:right-[2%] text-black border-black/50 m-2 p-2 border-[1px] hover:bg-aqs-blue-200 cursor-pointer"
              onClick={hideBookingBox}>
              <h1>X</h1>
            </div>

            {/* // END-------------------Fifth line-----------------------------------*** */}

            {/* // ***-------------------Sixth line-----------------------------------*** */}
            <ButtonBooking></ButtonBooking>
            {/* // END-------------------Sixth line-----------------------------------*** */}
          </div>
          <div className="flex flex-col justify-center">
            <img className="" id="logoTopMenu" src={imgBookingRight} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reserver;
