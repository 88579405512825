import React from "react";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";

type IProps = {
  name: string;
  icon: FontAwesomeIconProps["icon"];
};

const TopMenuItem: React.FC<IProps> = (props) => {
  return (
    <div className="flex items-center cursor-pointer hover:bg-aqs-blue-300 duration-1000 m-1 sm:h-24">
      <div className="p-1 m-3">
        <FontAwesomeIcon icon={props.icon} size="lg" />
      </div>
      <div className="font-josefin m-2 pt-2 text-2xl">{props.name}</div>
    </div>
  );
};

export default TopMenuItem;
