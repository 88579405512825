/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  faHome,
  faCalendar,
  faSun,
  faSnowflake,
  faPhone,
  faMap
} from "@fortawesome/free-solid-svg-icons";
import TopMenuItem from "./TopMenuItem";
import logoAQS from "../../images/logoaqsp.png";
import Reserver from "../booking/Reserver";
import MenuDropDown from "../Topmenu/MenuDropDown";
import { activities } from "../../types/activityType";
import {
  getActivitiesCardsSummer,
  getActivitiesCardsWinter,
  getActivitiesCardsYourte
} from "../../services/activityService";
import facebook from "../../../src/images/facebook.svg";
import twitter from "../../../src/images/twitter.svg";
import youtube from "../../../src/images/youtube.svg";
import { summerActivities, winterActivities, yourteActivities } from "../helper/defaultDataContent";

type IProps = {
  bookingState: boolean;
  dropDownState: boolean;
};

const TopMenu: React.FC<IProps> = () => {
  // ***-------------------Responsivenss const-----------------------------------***

  const [width, setWidth] = React.useState(window.innerWidth);

  const breakpoint = 1100;
  const AreWeMobile = (): Boolean => {
    if (width < breakpoint) {
      return true;
    } else {
      return false;
    }
  };
  const [areWeMobileState, setAreWeMobileState] = React.useState<Boolean>(AreWeMobile());

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    if (AreWeMobile() !== areWeMobileState) {
      setAreWeMobileState(AreWeMobile());
      showHideMenu(!areWeMobileState);
    }
  }, [width]);

  useEffect(() => {
    showHideMenu(!areWeMobileState);
  }, [areWeMobileState]);

  // END-------------------Responsivenss const-----------------------------------***

  // ***-------------------Show Menu-----------------------------------***
  const refMenu = useRef<HTMLDivElement>(null);
  const [menuShow, setmenuShow] = useState(!areWeMobileState);

  useEffect(() => {
    adjustMenuViewOnMobile();
  }, [menuShow]);

  const adjustMenuViewOnMobile = (): void => {
    if (menuShow) {
      if (areWeMobileState) {
        refMenu.current!.className =
          " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[400px] transition-all duration-1000";
      } else {
        refMenu.current!.className = " flex sm:flex-row flex-col ";
      }
    } else {
      refMenu.current!.className =
        "flex flex-col sm:flex-row h-0 opacity-0 bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  transition-all duration-1000";
    }
  };

  const toggleMenu = (): void => {
    if (menuShow) {
      setmenuShow(false);
    } else {
      setmenuShow(true);
    }
  };
  const showHideMenu = (state: Boolean): void => {
    state ? setmenuShow(true) : setmenuShow(false);
  };
  // END-------------------Show Menu-----------------------------------***

  // ***-------------------Booking Modal-----------------------------------***
  const refBookingBox = useRef<HTMLDivElement>(null);
  const [bookingDisplay, setBookingDisplay] = useState(false);

  useEffect(() => {
    if (bookingDisplay) {
      refBookingBox.current!.className =
        "relative mx-64 -top-[700px] -left-96 right-0 opacity-0 transition-all z-10 duration-300 ";
      setTimeout(() => {
        refBookingBox.current!.className =
          "relative mx-10 sm:mx-64 sm:left-0 -left-14 top-0 duration-500 opacity-100 transition-all z-30 duration-300 ";
      }, 100);
    }
    if (!bookingDisplay) {
      refBookingBox.current!.className =
        "relative mx-64 -top-[700px] -left-96 right-0 opacity-0 transition-all z-10 duration-300";
      setTimeout(() => {
        refBookingBox.current!.className = " hidden";
      }, 100);
    }
  }, [bookingDisplay]);

  const hideShowBookingBox = (): void => {
    setBookingDisplay(!bookingDisplay);
  };
  // END-------------------Booking Modal-----------------------------------***

  // ***-------------------Summer Menu-----------------------------------***
  const [hoverSummer, setHoverSummer] = useState(false);
  const [activitiesSummer, setActivitiesSummer] = useState<activities[]>(summerActivities);
  const boxSummer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverSummer) {
      if (areWeMobileState) {
        boxSummer.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-[200px] ease-in-out";
      } else {
        boxSummer.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-96 left-0";
      }
    }
    if (!hoverSummer) {
      if (areWeMobileState) {
        boxSummer.current!.className =
          "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
      } else {
        boxSummer.current!.className =
          " relative invisible -left-96 duration-500 opacity-0 sm:-ml-96 ml-96 h-0";
      }
    }
  }, [hoverSummer]);

  const toggleSummer = (): void => {
    refMenu.current!.className =
      " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[400px] transition-all duration-1000";

    if (hoverSummer) {
      setHoverSummer(false);
    }
    if (!hoverSummer) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[600px] transition-all duration-1000";
      setHoverSummer(true);
    }
  };
  const showSummer = (): void => {
    setHoverSummer(true);
  };
  const hideSummer = (): void => {
    if (areWeMobileState) {
      //Timeout so the LINK TO in ActMenuCard Can trigger before its being hide
      setTimeout(() => {
        setHoverSummer(false);
      }, 100);
    } else {
      setHoverSummer(false);
    }
  };
  // END-------------------Summer Menu-----------------------------------***

  // ***-------------------Winter Menu-----------------------------------***
  const [hoverWinter, setHoverWinter] = useState(false);
  const [activitiesWinter, setActivitiesWinter] = useState<activities[]>(winterActivities);
  const boxWinter = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverWinter) {
      if (areWeMobileState) {
        boxWinter.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-[170px] ease-in-out";
      } else {
        boxWinter.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-96 left-0";
      }
    }
    if (!hoverWinter) {
      if (areWeMobileState) {
        boxWinter.current!.className =
          "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
      } else {
        boxWinter.current!.className =
          " relative invisible -left-96 duration-500 opacity-0 sm:-ml-96 ml-96 h-0";
      }
    }
  }, [hoverWinter]);

  const toggleWinter = (): void => {
    if (hoverWinter) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[400px] transition-all duration-1000";
      setHoverWinter(false);
    }
    if (!hoverWinter) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[580px] transition-all duration-1000";
      setHoverWinter(true);
    }
  };
  const showWinter = (): void => {
    setHoverWinter(true);
  };
  const hideWinter = (): void => {
    if (areWeMobileState) {
      //Timeout so the LINK TO in ActMenuCard Can trigger before its being hide
      setTimeout(() => {
        setHoverWinter(false);
      }, 100);
    } else {
      setHoverWinter(false);
    }
  };
  // END-------------------Winter Menu-----------------------------------***

  // ***-------------------Yourte Menu-----------------------------------***
  const [hoverYourte, setHoverYourte] = useState(false);
  const [activitiesYourte, setActivitiesYourte] = useState<activities[]>(yourteActivities);
  const boxYourte = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (hoverYourte) {
      if (areWeMobileState) {
        boxYourte.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-[200px] ease-in-out";
      } else {
        boxYourte.current!.className =
          "relative visible duration-1000 opacity-100 z-30 h-96 left-0";
      }
    }
    if (!hoverYourte) {
      if (areWeMobileState) {
        boxYourte.current!.className =
          "relative visible duration-1000 opacity-0 z-0 h-0 ease-in-out";
      } else {
        boxYourte.current!.className =
          " relative invisible -left-96 duration-500 opacity-0 sm:-ml-96 ml-96 h-0";
      }
    }
  }, [hoverYourte]);

  const toggleYourte = (): void => {
    if (hoverYourte) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[400px] transition-all duration-1000";
      setHoverYourte(false);
    }
    if (!hoverYourte) {
      //Resizing menu with new dropmenu
      refMenu.current!.className =
        " flex sm:flex-row flex-col border-[0px] border-aqs-blue-200 m-1  bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50  h-[580px] transition-all duration-1000";
      setHoverYourte(true);
    }
  };

  const showYourte = (): void => {
    setHoverYourte(true);
  };
  const hideYourte = (): void => {
    if (areWeMobileState) {
      //Timeout so the LINK TO in ActMenuCard Can trigger before its being hide
      setTimeout(() => {
        setHoverYourte(false);
      }, 100);
    } else {
      setHoverYourte(false);
    }
  };
  // END-------------------Yourte Menu-----------------------------------***

  // ***-------------------Retrieving Activity-----------------------------------***
  useEffect(() => {
    getActivities();
  }, []);

  const getActivities = async () => {
    try {
      const fetchedActivitiesSummer: activities[] = await getActivitiesCardsSummer();
      setActivitiesSummer(fetchedActivitiesSummer);
      const fetchedActivitiesWinter: activities[] = await getActivitiesCardsWinter();
      setActivitiesWinter(fetchedActivitiesWinter);
      const fetchedActivitiesYourte: activities[] = await getActivitiesCardsYourte();
      setActivitiesYourte(fetchedActivitiesYourte);
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***

  // .----------------.  .----------------.  .-----------------. .----------------.  .----------------.  .----------------.
  // | .--------------. || .--------------. || .--------------. || .--------------. || .--------------. || .--------------. |
  // | |  _______     | || |  _________   | || | ____  _____  | || |  ________    | || |  _________   | || |  _______     | |
  // | | |_   __ \    | || | |_   ___  |  | || ||_   \|_   _| | || | |_   ___ `.  | || | |_   ___  |  | || | |_   __ \    | |
  // | |   | |__) |   | || |   | |_  \_|  | || |  |   \ | |   | || |   | |   `. \ | || |   | |_  \_|  | || |   | |__) |   | |
  // | |   |  __ /    | || |   |  _|  _   | || |  | |\ \| |   | || |   | |    | | | || |   |  _|  _   | || |   |  __ /    | |
  // | |  _| |  \ \_  | || |  _| |___/ |  | || | _| |_\   |_  | || |  _| |___.' / | || |  _| |___/ |  | || |  _| |  \ \_  | |
  // | | |____| |___| | || | |_________|  | || ||_____|\____| | || | |________.'  | || | |_________|  | || | |____| |___| | |
  // | |              | || |              | || |              | || |              | || |              | || |              | |
  // | '--------------' || '--------------' || '--------------' || '--------------' || '--------------' || '--------------' |
  //  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'  '----------------'
  return (
    <div className="flex flex-col w-full">
      <div className="z-20">
        <div className="flex sm:flex-row flex-col justify-between">
          <div className="flex m-1 items-center justify-center">
            <Link to="/">
              <img id="logoTopMenu" src={logoAQS} />
            </Link>
          </div>

          {/* --------------------MENU hide on Mobile only----------------------------------------------- */}
          {areWeMobileState ? (
            <div onClick={toggleMenu} className="flex items-center justify-center">
              <div className=" border-[1px] border-aqs-blue-700 p-2 hover:bg-aqs-blue-200">
                MENU
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {/* END-----------------MENU hide on Mobile only------------------------------------------------ */}

          {/* --------------------Booking clic cause Close Menu on booking click only mobil--------------- */}
          <div
            className="flex sm:flex-row flex-col opacity-0 h-[800px] border-[0px] border-aqs-blue-200 mt-1 bg-gradient-to-b from-aqs-green-50/10 to-aqs-blue-900/50 "
            ref={refMenu}>
            {areWeMobileState ? (
              <div onClick={toggleMenu}>
                <div onClick={hideShowBookingBox}>
                  <TopMenuItem icon={faCalendar} name="Réserver" />
                </div>
              </div>
            ) : (
              <div onClick={hideShowBookingBox}>
                <TopMenuItem icon={faCalendar} name="Réserver" />
              </div>
            )}
            {/* END-----------------Booking clic cause Close Menu on booking click only mobil-------------- */}

            {/* --------------------Close Menu on TopItemClick only on mobile------------------------------- */}
            {areWeMobileState ? (
              <div>
                <div className="w-full overflow-hidden ">
                  <div id="summer" onClick={toggleSummer} onBlur={hideSummer}>
                    <div>
                      <Link to="/ete">
                        <TopMenuItem icon={faSun} name="Été" />
                      </Link>
                    </div>
                  </div>
                  <div className="relative invisible mx-64 opacity-0 h-0" ref={boxSummer}>
                    <div onClick={toggleMenu}>
                      <MenuDropDown activities={activitiesSummer} mobileView={true}></MenuDropDown>
                    </div>
                  </div>
                </div>

                <div className="w-full overflow-hidden">
                  <div id="winter" onClick={toggleWinter} onBlur={hideWinter}>
                    <div>
                      <Link to="/hiver">
                        <TopMenuItem icon={faSnowflake} name="Hiver" />
                      </Link>
                    </div>
                  </div>
                  <div className="relative invisible mx-64 opacity-0 h-0" ref={boxWinter}>
                    <div onClick={toggleMenu} className="">
                      <MenuDropDown activities={activitiesWinter} mobileView={true}></MenuDropDown>
                    </div>
                  </div>
                </div>

                <div className="w-full overflow-hidden">
                  <div id="yourte" onClick={toggleYourte} onBlur={hideYourte}>
                    <div>
                      <Link to="/yourte">
                        <TopMenuItem icon={faHome} name="Yourte" />
                      </Link>
                    </div>
                  </div>
                  <div className="relative invisible mx-64 opacity-0 h-0" ref={boxYourte}>
                    <div onClick={toggleMenu} className="">
                      <MenuDropDown activities={activitiesYourte} mobileView={true}></MenuDropDown>
                    </div>
                  </div>
                </div>
                <div>
                  <Link onClick={toggleMenu} to="/carte">
                    <TopMenuItem icon={faMap} name="Carte" />
                  </Link>
                </div>
                <div>
                  <Link onClick={toggleMenu} to="/contact">
                    <TopMenuItem icon={faPhone} name="Contact" />
                  </Link>
                </div>
              </div>
            ) : (
              <div className="flex">
                <div
                  id="summer"
                  onMouseEnter={showSummer}
                  onMouseLeave={hideSummer}
                  onClick={hideSummer}>
                  <div>
                    <Link to="/ete">
                      <TopMenuItem icon={faSun} name="Été" />
                    </Link>
                  </div>
                </div>
                <div
                  id="winter"
                  onMouseEnter={showWinter}
                  onMouseLeave={hideWinter}
                  onClick={hideWinter}>
                  <div>
                    <Link to="/hiver">
                      <TopMenuItem icon={faSnowflake} name="Hiver" />
                    </Link>
                  </div>
                </div>

                <div
                  id="yourte"
                  onMouseEnter={showYourte}
                  onMouseLeave={hideYourte}
                  onClick={hideYourte}>
                  <div>
                    <Link to="/yourte">
                      <TopMenuItem icon={faHome} name="Yourte" />
                    </Link>
                  </div>
                </div>
                <div>
                  <Link to="/carte">
                    <TopMenuItem icon={faMap} name="Carte" />
                  </Link>
                </div>
                <div>
                  <Link to="/contact">
                    <TopMenuItem icon={faPhone} name="Contact" />
                  </Link>
                </div>
              </div>
            )}
          </div>
          {/* END-----------------Close Menu on TopItemClick only on mobile------------------------------- */}

          {/* --------------------FaceBook - Twitter - Youtube-------------------------------------------- */}
          <div className="flex flex-row items-center justify-center m-3">
            <div className="p-2 hover:scale-[110%] duration-1000">
              <a
                href="https://www.facebook.com/aventurequatresaisons/"
                target="_blank"
                rel="noreferrer">
                <img src={facebook} alt="Notre page Facebook" width="50" />
              </a>
            </div>
            <div className="p-2 hover:scale-[110%] duration-1000">
              <a href="https://twitter.com/aventure4saison/" target="_blank" rel="noreferrer">
                <img src={twitter} alt="Notre page Twitter" width="50" />
              </a>
            </div>
            <div className="p-2 hover:scale-[110%] duration-1000">
              <a
                href="https://www.youtube.com/user/aventurequatresaison"
                target="_blank"
                rel="noreferrer">
                <img src={youtube} alt="Notre chaîne Youtube" width="50" />
              </a>
            </div>
          </div>
          {/* END----------------FaceBook - Twitter - Youtube-------------------------------------------- */}
        </div>
      </div>

      {/* ----------------------------------Booking Component-------------------------------------------- */}
      <div
        className="relative mx-28 -left-96 -top-96 transition-all duration-500 opacity-0 hidden "
        ref={refBookingBox}>
        <Reserver hideBookingBox={hideShowBookingBox}></Reserver>
      </div>
      {/* END-------------------------------Booking Component-------------------------------------------- */}

      {/* ----------------------------------MenuDropDown For PC view Only-------------------------------- */}
      {areWeMobileState ? (
        <div></div>
      ) : (
        <div>
          <div
            className="relative invisible opacity-0 h-0"
            onMouseEnter={showSummer}
            onMouseLeave={hideSummer}
            onClick={hideSummer}
            ref={boxSummer}>
            <MenuDropDown activities={activitiesSummer}></MenuDropDown>
          </div>
          <div
            className="relative invisible opacity-0 h-0"
            onMouseEnter={showWinter}
            onMouseLeave={hideWinter}
            onClick={hideWinter}
            ref={boxWinter}>
            <MenuDropDown activities={activitiesWinter}></MenuDropDown>
          </div>
          <div
            className="relative invisible opacity-0 h-0"
            onMouseEnter={showYourte}
            onMouseLeave={hideYourte}
            onClick={hideYourte}
            ref={boxYourte}>
            <MenuDropDown activities={activitiesYourte}></MenuDropDown>
          </div>
        </div>
      )}
      {/* END-------------------------------MenuDropDown For PC view Only-------------------------------- */}
    </div>
  );
};

export default TopMenu;
