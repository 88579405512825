import React, { useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesCanoe } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { canoePackages } from "../helper/defaultDataContent";

const Canot: React.FC = () => {
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(canoePackages);
  const title = "Canot";

  // ***-------------------Retrieving Activity-----------------------------------***
  useEffect(() => {
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesCanoe();
      setPackagesCanoeState(fetchedPackagesCanoe);
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div className="z-20 w-full">
      <MainContentPage title={title} packagesCanoe={packagesCanoeState}></MainContentPage>
    </div>
  );
};

export default Canot;
