/* eslint-disable no-unused-vars */
import React from "react";
import contactBg from "../../../src/images/contact.jpg";
import { faPhone, faEnvelope, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "../mainContentComponent/Footer";

const Contact: React.FC = () => {
  return (
    <div className="bg-gradient-to-b to-aqs-green-500 from-aqs-green-200 z-20">
      <div className="flex flex-col sm:flex-row">
        <div className="">
          <img
            src={contactBg}
            alt="Yourte sous les étoiles"
            style={{ width: "100%", height: "100%" }}
            className="sm:w-44"
          />
        </div>
        <div className="flex flex-col items-center justify-around text-6xl m-4">
          <div className="font-josefin font-extrabold">
            <h1>Contact</h1>
            <hr />
          </div>
          <div className="mb-16 text-sm sm:text-2xl">
            <div className="flex flex-row m-3">
              <div className="p-3">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div className="p-3">
                <a href="tel:514-554-5091">514-554-5091</a>
              </div>
            </div>
            <div className="flex flox-row m-3">
              <div className="p-3">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div className="p-3">
                <a href="mailto:info@aventurequatresaisons.com">info@aventurequatresaisons.com</a>
              </div>
            </div>
            <div className="flex flex-row m-3">
              <div className="p-3">
                <FontAwesomeIcon icon={faMap} />
              </div>
              <div className="p-3">
                <a
                  href="http://maps.google.com/?q=4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0"
                  target="_blank"
                  rel="noreferrer">
                  4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Contact;
