import React from "react";
import { defaultContentText } from "./../helper/defaultDataContent";

const BookingPolicy: React.FC = () => {
  return (
    <div
      className="flex flex-col bg-gradient-to-r to-aqs-green-400 from-aqs-blue-500 rounded-b-2xl rounded-3xl text-white"
      id="bookingPolicy">
      <div className="m-3 p-5 flex justify-center">
        <div className="flex flex-wrap flex-col bg-aqs-blue-50/50 border-[1px] border-white rounded-xl h-[70%]">
          <div className="m-3 p-3 font-josefin font-extrabold text-center text-3xl">
            <h1>{defaultContentText.bookingPolicy.title}</h1>
          </div>
          <hr className="mx-10" />
          <div className="m-3 p-3 font-josefin font-extrabold text-center text-2xl">
            <h1>{defaultContentText.bookingPolicy.subtitle}</h1>
          </div>
          <div className="m-3 p-3">
            <h1>{defaultContentText.bookingPolicy.description}</h1>
          </div>
          <div className="m-3 p-3">
            <h1>{defaultContentText.bookingPolicy.descriptionFollowUp}</h1>
          </div>
          <hr className="mx-10" />
          <div className="m-3 p-3 font-josefin font-extrabold text-center text-2xl">
            <h1>{defaultContentText.bookingPolicy.titleCancelling}</h1>
          </div>
          <div className="m-3 p-3">
            <h1>{defaultContentText.bookingPolicy.descriptionCancelling}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPolicy;
