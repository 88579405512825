// const URL = "http://localhost:3010";
const URL = process.env.REACT_APP_API_BASE_URL;

export const getActivitiesCards = async <activities>(): Promise<activities[]> => {
  const response = await fetch(`${URL}/activities/allActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getActivitiesCardsSummer = async <activities>(): Promise<activities[]> => {
  const response = await fetch(`${URL}/activities/summerActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getActivitiesCardsWinter = async <activities>(): Promise<activities[]> => {
  const response = await fetch(`${URL}/activities/winterActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getActivitiesCardsYourte = async <activities>(): Promise<activities[]> => {
  const response = await fetch(`${URL}/activities/yourteActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};
