import React from "react";
import carte from "../../../src/images/carte.png";
import Title from "../mainContentComponent/Title";
import OpenBookingMenuButton from "../mainContentComponent/OpenBookingMenuButton";
import Comments from "../mainContentComponent/Comments";
import ContactFooter from "../mainContentComponent/ContactFooter";
import BookingPolicy from "../mainContentComponent/BookingPolicy";
import Footer from "../mainContentComponent/Footer";

const Carte: React.FC = () => {
  return (
    <div className="z-20">
      <div className="sm:m-8 duration-300 sm:w-auto">
        <div>
          <Title title={"Carte"}></Title>
        </div>
        <div className="flex justify-center">
          <OpenBookingMenuButton></OpenBookingMenuButton>
        </div>
      </div>
      <div className="flex text-2xl m-5 justify-center text-center">
        <h1>Consultez les différents parcours sur cette carte.</h1>
      </div>
      <div className="m-16 hover:scale-[101%] duration-300 border-4 rounded-lg">
        <img src={carte} alt="Carte de la rivière rouge" />
      </div>
      <div className="sm:m-8  sm:w-auto hover:scale-[102%] p-2 duration-300 text-justify">
        <Comments></Comments>
      </div>
      <div className="sm:m-8 sm:w-auto  hover:scale-[102%] p-2 duration-300  text-justify">
        <BookingPolicy></BookingPolicy>
      </div>
      <div className="sm:m-8 sm:w-auto hover:scale-[102%] p-2 duration-300  text-justify">
        <ContactFooter></ContactFooter>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Carte;
