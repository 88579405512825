import React, { useEffect, useState } from "react";
import { packages } from "../../types/packagesTypes";
import { getPackagesSled } from "../../services/packageService";
import MainContentPage from "../mainContentComponent/MainContentPage";
import { sledVipPackages } from "../helper/defaultDataContent";

const TraineauChienVIP: React.FC = () => {
  const [packagesCanoeState, setPackagesCanoeState] = useState<packages[]>(sledVipPackages);
  const title = "Traineau à chien VIP";

  // ***-------------------Retrieving Activity-----------------------------------***
  useEffect(() => {
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const fetchedPackagesCanoe: packages[] = await getPackagesSled();
      setPackagesCanoeState(fetchedPackagesCanoe.reverse()); //Revers to put VIP in top
    } catch (err) {
      throw new Error(err as string);
    }
  };
  // END-------------------Retrieving Activity-----------------------------------***
  return (
    <div className="z-20 w-full">
      <MainContentPage title={title} packagesCanoe={packagesCanoeState}></MainContentPage>
    </div>
  );
};

export default TraineauChienVIP;
