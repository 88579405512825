import React, { useEffect, useRef, useState } from "react";
import Reserver from "../booking/Reserver";
import { v4 as uuidv4 } from "uuid";

const OpenBookingMenuButton: React.FC = () => {
  // ***-------------------Booking Modal-----------------------------------***
  const [bookingDisplay, setBookingDisplay] = useState(false);
  const refBookingBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bookingDisplay) {
      refBookingBox.current!.className =
        "fixed top-44 sm:left-24 sm:right-0 sm:w-auto left-0 w-fit opacity-100 z-30";
    }
    if (!bookingDisplay) {
      refBookingBox.current!.className = "fixed top-0 left-0 right-0 opacity-0 z-30 hidden";
    }
  }, [bookingDisplay]);

  const hideShowBookingBox = (): void => {
    setBookingDisplay(!bookingDisplay);
  };
  // END-------------------Booking Modal-----------------------------------***
  return (
    <div className="m-3 p-3 flex sm:justify-center items-center text-center text-xl">
      <div className="fixed top-44 left-8 right-0 opacity-0 hidden" ref={refBookingBox}>
        <div className="fixed sm:bg-white h-screen w-screen -left-2 top-0 opacity-50"></div>
        <Reserver key={uuidv4()} hideBookingBox={hideShowBookingBox}></Reserver>
        {/* <div className="w-96 h-96 bg-red left-1/2"></div> */}
      </div>
      <div
        onClick={hideShowBookingBox}
        className="border-2 border-white rounded-md sm:w-80 w-56 hover:animate-pulse cursor-pointer hover:scale-[110%] duration-1000">
        Réservez en ligne!
      </div>
    </div>
  );
};

export default OpenBookingMenuButton;
