/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import PackageCards from "./PackageCards";
import PackagePreview from "./PackagePreview";
import { packages } from "../../../types/packagesTypes";

type IProps = {
  packagesCanoe: packages[];
};

const PackageDisplay: React.FC<IProps> = ({ packagesCanoe }) => {
  // ***-------------------Selected Package-----------------------------------***
  const [selectedPackage, setselectedPackage] = useState<packages>(packagesCanoe[0]);

  useEffect(() => {
    setselectedPackage(packagesCanoe[0]);
  }, [packagesCanoe]);

  const selectPackage = (packageToSelect: packages): void => {
    setselectedPackage(packageToSelect);
  };
  // END-------------------Selected Package-----------------------------------***

  return (
    <div className="flex sm:flex-row flex-col bg-gradient-to-r to-aqs-green-400 from-aqs-blue-900 rounded-b-2xl">
      <PackagePreview selectedPackage={selectedPackage}></PackagePreview>
      <PackageCards packageCard={packagesCanoe} selectPackageProps={selectPackage}></PackageCards>
    </div>
  );
};

export default PackageDisplay;
