import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import "./App.css";
import TopMenu from "./components/Topmenu/TopMenu";
import Carte from "./components/mainpages/Carte";
import Contact from "./components/mainpages/Contact";
import Canot from "./components/mainpages/Canot";
import { v4 as uuidv4 } from "uuid";
import Kayak from "./components/mainpages/Kayak";
import Sup from "./components/mainpages/Sup";
import CanotCamping from "./components/mainpages/CanotCamping";
import TraineauChien from "./components/mainpages/TraineauChien";
import TraineauChienVIP from "./components/mainpages/TraineauChienVIP";
import RaquetteTractee from "./components/mainpages/RaquetteTractee";
import YourteOneNight from "./components/mainpages/YourteOneNight";

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <div className="flex h-auto">
          <TopMenu key={uuidv4()} bookingState={false} dropDownState={false} />
        </div>
        <div className="flex bg-gradient-to-b to-aqs-green-900 from-aqs-green-50/20">
          <Routes>
            <Route path="/" element={<Canot />} />
            <Route path="/ete" element={<Canot />} />
            <Route path="/canot" element={<Canot />} />
            <Route path="/kayak" element={<Kayak />} />
            <Route path="/standup%20paddle" element={<Sup />} />
            <Route path="/canot-camping" element={<CanotCamping />} />
            <Route path="/hiver" element={<TraineauChien />} />
            <Route path="/traineau%20%C3%A0%20chiens" element={<TraineauChien />} />
            <Route path="/traineau%20%C3%A0%20chiens%20-vip-" element={<TraineauChienVIP />} />
            <Route path="/raquette%20tract%C3%A9e" element={<RaquetteTractee />} />
            <Route path="/yourte" element={<YourteOneNight />} />
            <Route path="/nuit%C3%A9e%20en%20yourte" element={<YourteOneNight />} />
            <Route path="/2%20nuits%20en%20yourte" element={<YourteOneNight />} />
            <Route path="/3%20nuits%20en%20yourte" element={<YourteOneNight />} />
            <Route path="/carte" element={<Carte />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};
export default App;
