import React from "react";
import { packages } from "../../../types/packagesTypes";

type IProps = {
  packageOne: packages;
  // eslint-disable-next-line no-unused-vars
  selectPackageProps: (packageToSelect: packages) => void;
};

const packageOne: React.FC<IProps> = ({ packageOne, selectPackageProps }) => {
  // ***-------------------Selected Package Props-----------------------------------***

  const selectPackage = (): void => {
    selectPackageProps(packageOne);
  };
  // END-------------------Selected Package Props-----------------------------------***
  return (
    <a rel="noopener" href="#displayPackageRef">
      <div
        onClick={selectPackage}
        className="flex flex-col bg-cover bg-center justify-between h-[200px] cursor-pointer hover:animate-pulse border-[1px] mt-5 mx-3  hover:scale-[103%] duration-1000 rounded-xl overflow-hidden"
        style={{ backgroundImage: `url(${packageOne?.picUrl})` }}>
        <div className="flex justify-center bg-aqs-green-400/80 w-full border-b-[1px]">
          <div className="sm:text-2xl text-xl font-semibold text-black/60 p-1">
            {packageOne?.name.toUpperCase()}
          </div>
        </div>
        <div className="flex justify-left  w-full bg-aqs-blue-300/80 text-white px-1 border-t-[1px]">
          <div className="">{packageOne?.inclusions}</div>
        </div>
      </div>
    </a>
  );
};

export default packageOne;
