import React from "react";

type IProps = {
  title: string;
};

const Title: React.FC<IProps> = ({ title }) => {
  return (
    <div
      className="flex flex-row h-24 text-white font-josefin bg-gradient-to-b from-aqs-green-700 to-aqs-blue-400/20 text-5xl 
                  sm:text-7xl font-extrabold justify-center rounded-t-full ">
      <h1 className="bg-aqs-blue-800/40 pt-2 px-16 border-x-[1px] border-t-[1px] rounded-t-full hover:scale-110 duration-1000 text-center">
        {title}
      </h1>
    </div>
  );
};

export default Title;
