// const URL = "http://localhost:3010";
const URL = process.env.REACT_APP_API_BASE_URL;

export const getPackagesCards = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/allActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCardsSummer = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/summerActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCardsWinter = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/winterActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCardsYourte = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/yourteActivities/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesCanoe = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/canoePackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesKayak = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/kayakPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesSup = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/supPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesSled = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/sledPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesJorring = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/jorringPackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};

export const getPackagesYourte = async <packages>(): Promise<packages[]> => {
  const response = await fetch(`${URL}/packages/yourtePackages/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  try {
    return await response.json();
  } catch (err) {
    throw new Error(err as string);
  }
};
