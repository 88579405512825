/* eslint-disable no-unused-vars */
import React from "react";
import PackageDisplay from "./package/PackageDisplay";
import Title from "./Title";
import Description from "./Description";
import BookingPolicy from "./BookingPolicy";
import Comments from "./Comments";
import ContactFooter from "./ContactFooter";
import Footer from "./Footer";
import { packages } from "../../types/packagesTypes";
type IProps = {
  title: string;
  packagesCanoe: packages[];
};
const MainContentPage: React.FC<IProps> = ({ title, packagesCanoe }) => {
  return (
    <div>
      <div className="sm:m-8 duration-300 sm:w-auto">
        <div>
          <Title title={title}></Title>
        </div>
        <div id="displayPackageRef" className="flex h-[70%] bg-white sm:w-[100%]  text-justify">
          <PackageDisplay packagesCanoe={packagesCanoe}></PackageDisplay>
        </div>
      </div>
      <div className="sm:m-8 sm:w-auto duration-300 p-2 text-justify">
        <Description></Description>
      </div>
      <div className="sm:m-8  sm:w-auto hover:scale-[102%] p-2 duration-300 text-justify">
        <Comments></Comments>
      </div>
      <div className="sm:m-8 sm:w-auto  hover:scale-[102%] p-2 duration-300  text-justify">
        <BookingPolicy></BookingPolicy>
      </div>
      <div className="sm:m-8 sm:w-auto hover:scale-[102%] p-2 duration-300  text-justify">
        <ContactFooter></ContactFooter>
      </div>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
};

export default MainContentPage;
