/* eslint-disable no-unused-vars */
import React from "react";
import { faPhone, faEnvelope, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ContactFooter: React.FC = () => {
  return (
    <div className="flex justify-center bg-gradient-to-r to-aqs-green-200/50 from-aqs-green-500/50 rounded-b-2xl rounded-3xl">
      <div className=" flex justify-center flex-col">
        <div className="flex justify-center font-josefin font-extrabold text-3xl">
          <h1>Contact</h1>
        </div>
        <div className=" flex sm:flex-row justify-center text-xl flex-col">
          <div className="flex flex-row m-3">
            <div className="m-1">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <div className="m-1">
              <p>514-554-5091</p>
            </div>
          </div>
          <div className="flex flex-row m-3">
            <div className="m-1">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div className="m-1">
              <p>info@aventurequatresaisons.com</p>
            </div>
          </div>
          <div className="flex flex-row m-3">
            <div className="m-1">
              <FontAwesomeIcon icon={faMap} />
            </div>
            <div className="m-1">
              <p>4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFooter;
