import React from "react";
import { activities } from "../../types/activityType";
import { Link } from "react-router-dom";

type IProps = {
  activity: activities;
  mobileView?: boolean;
};

const ActMenuCard: React.FC<IProps> = ({ activity, mobileView }) => {
  return (
    <div className="flex w-full sm:w-auto">
      {mobileView ? (
        <div
          className="flex justify-center m-1 border-[1px] border-white w-full bg-fixed"
          style={{ backgroundImage: `url(${activity?.picUrl})` }}>
          <div className="flex font-josefin  bg-black bg-opacity-40 font-extrabold p-1 m-1">
            <Link to={activity.name.toLowerCase()}>
              <h1>{activity.name}</h1>
            </Link>
          </div>
        </div>
      ) : (
        <Link to={activity.name.toLowerCase()}>
          <div
            className="flex bg-cover bg-center flex-col w-96 h-96 cursor-pointer hover:animate-pulse z-30 "
            style={{ backgroundImage: `url(${activity.picUrl})` }}>
            <div className="flex flex-col justify-between h-full text-justify">
              <div>
                <div className="flex justify-center bg-aqs-green-400 bg-opacity-50 w-full">
                  <div className="text-4xl text-black text">{activity.name}</div>
                </div>
              </div>
              <div>
                <div className="bg-aqs-blue-300 bg-opacity-50 w-2/3  text-white">
                  <div className="flex justify-left w-full">
                    <div>Embarcation {activity.boatPlace}</div>
                  </div>
                  <div className="flex justify-start w-full">
                    <div>{activity.duration}</div>
                  </div>
                  <div className="flex justify-start w-full">
                    <div>{activity.departureHour}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex justify-center p-2 bg-aqs-blue-800  bg-opacity-50 text-white">
                  <div className="text-4xl">{activity.pricing}$/Pers.</div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
};

export default ActMenuCard;
