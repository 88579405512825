import React from "react";
import { defaultContentText } from "./../helper/defaultDataContent";

const Comments: React.FC = () => {
  return (
    <div className="bg-gradient-to-r to-aqs-green-200 from-aqs-green-500 rounded-b-2xl rounded-3xl">
      <div className="p-5 flex justify-center">
        <div className="">
          <div className="m-3 p-3 text-center text-3xl">
            <h1>{defaultContentText.comments.commentTitle}</h1>
          </div>
          <div className="m-3 p-3 text-center">
            <h1>{defaultContentText.comments.comment}</h1>
          </div>
          <div className="m-3 p-3 text-center">
            <h1>{defaultContentText.comments.CommentAuthor}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comments;
