/* eslint-disable no-unused-vars */
import React from "react";
import { packages } from "../../../types/packagesTypes";
import OpenBookingMenuButton from "../OpenBookingMenuButton";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  selectedPackage: packages;
};

const PackagePreview: React.FC<IProps> = ({ selectedPackage }) => {
  return (
    <div className="sm:w-[60%] m-2 sm:m-4 bg-aqs-blue-50/50 border-[1px] border-white rounded-xl overflow-hidden">
      <div className="flex flex-col sm:h-full">
        <div className="flex flex-col sm:flex-row sm:h-[75%]">
          <div className=" flex justify-center sm:w-[60%] border-2 border-white overflow-hidden rounded-xl sm:rounded-l-xl">
            <img
              className="hover:scale-125 object-contain hover:opacity-90 transition ease-in-out delay-150 duration-500"
              src={selectedPackage?.picUrl}
            />
          </div>
          <div className="flex flex-col sm:w-[40%] border-2 border-white overflow-hidden rounded-xl sm:rounded-r-xl p-3 justify-between items-center ">
            <div className="flex items-center sm:h-full text-4xl text-center sm:text-left font-semibold text-black/60 p-1">
              <h1>{selectedPackage?.name.toUpperCase()}</h1>
            </div>
            <div className=" pb-4">
              <h6>{selectedPackage?.description}</h6>
            </div>
          </div>
        </div>
        <div className=" flex flex-col items-center m-1 sm:flex-row justify-around">
          <div className="flex flex-col text-2xl text-white justify-between ">
            <div className="flex m-3 p-3 justify-center">
              <h1>{selectedPackage?.inclusions}</h1>
            </div>
            <div className="flex justify-center">
              <OpenBookingMenuButton key={uuidv4()} />
            </div>
          </div>
          <div className="flex w-fit p-3 m-3 text-5xl sm:text-7xl bg-aqs-blue-300 border-[1px] border-orange rounded-3xl text-white">
            <div>
              <h1>{selectedPackage?.pricing}$/Pers.</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePreview;
