import React from "react";
import facebook from "../../../src/images/facebook.svg";
import twitter from "../../../src/images/twitter.svg";
import youtube from "../../../src/images/youtube.svg";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <div className="bg-aqs-green-50/50 flex justify-center sm:w-auto">
      <div className="flex sm:flex-row flex-col">
        <div className="border-2 border-white rounded-3xl m-5">
          <div className="text-xl m-3 ">
            <h1>Contact principal</h1>
          </div>
          <div className="text-sm m-2">
            <h1>Aventure Quatre Saisons</h1>
          </div>
          <div className="text-sm m-2">
            <a
              href="http://maps.google.com/?q=4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0"
              target="_blank"
              rel="noreferrer">
              4781 Chemin de la Rivière Nord Rivière-Rouge, QC J0T 1T0
            </a>
          </div>
          <div className="text-sm m-2">
            <a href="tel:514-554-5091">514-554-5091</a>
          </div>
          <div className="text-sm m-2">
            <a href="mailto:info@aventurequatresaisons.com">info@aventurequatresaisons.com</a>
          </div>
        </div>
        <div className="flex flex-col justify-between lg:w-[30%] border-2 border-white rounded-3xl m-5">
          <div className="text-xl m-3">
            <h1>Pourquoi nous choisir?</h1>
          </div>
          <div className="text-sm m-2">
            <h1>✓Service impécable</h1>
          </div>
          <div className="text-sm m-2">
            <h1>✓ Meilleur rapport qualité / prix</h1>
          </div>
          <div className="text-sm m-2">
            <h1> ✓ Guides passionnés du plein air </h1>
          </div>
          <div className="text-sm m-2">
            <h1> ✓ Embarcations de qualités</h1>
          </div>
          <div className="text-sm m-2">
            <h1> ✓ Site majestueux</h1>
          </div>
        </div>
        <div className=" border-2 border-white rounded-3xl m-5">
          <div className="text-xl m-3">
            <h1>Besoin d&apos;aide ?</h1>
          </div>
          <div className="text-sm m-2">
            <ul className="list-disc p-3">
              <a rel="noopener" href="#description">
                <li>Comment faire une réservation ?</li>
              </a>
              <a rel="noopener" href="#bookingPolicy">
                <li>Polituque d&apos;annulation et paiments</li>
              </a>
              <Link to="/contact">
                <li>Contact</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="flex flex-col justify-between border-2 border-white rounded-3xl m-5">
          <div className="text-xl m-3">
            <h1>Suivez-nous!</h1>
          </div>
          <div className="text-sm m-2">
            <h1>Nos meilleurs rabais se retrouve tous sur nos réseaux</h1>
          </div>
          <div className="text-sm flex flex-row justify-center m-3">
            <a
              href="https://www.facebook.com/aventurequatresaisons/"
              target="_blank"
              rel="noreferrer">
              <img src={facebook} alt="Notre page Facebook" width="50" />
            </a>
            <a href="https://twitter.com/aventure4saison/" target="_blank" rel="noreferrer">
              <img src={twitter} alt="Notre page Twitter" width="50" />
            </a>
            <a
              href="https://www.youtube.com/user/aventurequatresaison"
              target="_blank"
              rel="noreferrer">
              <img src={youtube} alt="Notre chaîne Youtube" width="50" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
