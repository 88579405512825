/* eslint-disable no-unused-vars */
import { activities } from "../../types/activityType";
import { defaultContentType } from "../../types/defaultContentType";
import { packages } from "../../types/packagesTypes";

export const defaultContentText: defaultContentType = {
  mission: {
    title: "Vous voulez une aventure nature... \n Près du Mont-Tremblant !",
    subtitle: "Sortez ENFIN avec une descente sur la Rivière-Rouge!",
    description:
      "Les Laurentides et la région de Mont-Tremblant valent absolument le détour! " +
      "Pour le nautique, que ce soit pour faire du canot, du rafting, du kayak ou du stand-up paddleboard( SUP )! " +
      "La descente de la Rivière Rouge est une activité nautique attrayante et fougueuse qui fait le bonheur de nombreux canoteurs et " +
      "kayakistes année après année. Aventure Quatre Saisons vous propose des descentes en rivière pour tous. " +
      "Que ce soit en formule 15 km ou encore en canot-camping de 2 à 5 jours sur les plages de la Rivière-Rouge, nous avons ce que vous cherchez! " +
      "Un paysage à couper le souffle agrémenté d'une touche d'exotisme grâce à la présence de nos yourtes importées de Mongolie! " +
      "Chez nous, vous avez le choix! : Traîneau à chiens, Rafting, Canot, Kayak sans oublier la nouvelle vedette de l’heure, Le SUP (Stand-up Paddleboard)",
    subtitleWinter: "Profitez de l'hiver avec nos forfaits de traîneau à chiens!",
    descriptionWinter:
      "Chez Aventure Quatre Saisons, c'est toute une équipe canine qui vous attend. " +
      "Naviguez à travers nos différents forfaits afin de voir l'étendue de ce que nos athlètes adorés sont capables de faire!"
  },
  bookingPolicy: {
    title: "Comment bien planifier votre activité chez nous ?",
    subtitle: "Politique de réservation",
    description:
      "Afin de bien pouvoir planifier votre arrivée et offrir des séjours de qualités, Aventure Quatre Saisons demande une réservation pour tous les séjours. " +
      "Lors de votre réservation, un mode de paiement vous est demandé et vous devrez obligatoirement fournir un numéro valide de carte de crédit afin de pouvoir " +
      "prendre la totalité de la valeur du séjour. Ce montant n'est pas remboursable. Si vous devez annuller/modifier votre activité, le total ne sera pas remboursé.",
    descriptionFollowUp:
      "Svp assurez-vous que votre carte est valide jusqu'à la date de votre sortie.",
    titleCancelling: "Annulation d'un séjour",
    descriptionCancelling:
      "Le montant total de la prestation est non-remboursable et vous sera facturé à la réservation."
  },
  activiteCanot: {
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous !" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière" +
      "Rouge et de profiter allègrement de nos deux îles de sable blond. Notez que cette" +
      "descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une" +
      "aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous" +
      "comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous" +
      "souhaitez pour descendre la rivière car vous terminerez à votre voiture. La navette" +
      "incluse se fait au début de l'activité. Un bain de soleil ou une pause pique" +
      "nique sur les nombreuses plages de sable blond agrémenteront l'expérience." +
      "L'activité parfaite pour profiter pleinement d'une belle journée" +
      "d'été."
  },
  comments: {
    commentTitle: "Bien encadré !",
    comment:
      "LA place pour vos vacances et surtout le traineau à chiens, ne cherchez plus c'est là que ça se passe! " +
      "Merci à toute l'équipe vous avez fait un travail remarquable. Et Quels chiens ! À bientôt",
    CommentAuthor: "-Matt R"
  }
};
export const summerActivities: activities[] = [
  {
    name: "Canot",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Canot (Duo)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "9h, 10h, 11h, 13h",
    duration: "15km, 25km, Canot-Camping(25km, 34km)",
    picUrl: "https://www.aventurequatresaisons.com/images/canot15km.jpg"
  },
  {
    name: "Kayak",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de faire du kayak paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Kayak (Solo seulement)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "SOLO",
    departureHour: "10h, 11h, 13h",
    duration: "15km, 25km",
    picUrl: "https://www.aventurequatresaisons.com/images/kayakact.jpg"
  },
  {
    name: "StandUp Paddle",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de faire du SUP paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-PaddleBoard (Solo seulement)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "SOLO",
    departureHour: "10h, 11h, 13h",
    duration: "15km",
    picUrl: "https://www.aventurequatresaisons.com/images/supact.jpg"
  },
  {
    name: "Canot-Camping",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre ",
    inclusions:
      "-Canot (Duo)\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "9h, 10h",
    duration: "25km, 34km",
    picUrl: "https://www.aventurequatresaisons.com/images/canotcampingact.jpg"
  }
];

export const winterActivities: activities[] = [
  {
    name: "Traineau à chiens",
    pricing: 129,
    activityType: "winter",
    description:
      "La randonnée de une heures permet aux participants de conduire leur propre embarcation en alternance et d'aller voir les sentiers plus techniques." +
      "Accompagnés par un guide qui ouvre la route, les chiens sillonnent des sentiers boisés et le champ pour un parcours féérique." +
      "La neige alourdissant les branches des arbres fait un paysage incroyable digne de nos belles Laurentides." +
      "Les chiens motivés quant à eux apprécieront vos caresses à la fin de la randonnée.\n" +
      "***Vous vous déplacer pour presque 2 heures d'activité avec tous ce qui entoure votre sortie! Est-ce que les chiots sauront vous charmer !",
    inclusions: "-Stationnement\n" + "-Matériel pour activité\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "9h, 11h, 13h",
    duration: "1h",
    picUrl: "https://www.aventurequatresaisons.com/images/traineau1h.jpg"
  },
  {
    name: "Traineau à chiens -VIP-",
    pricing: 269,
    activityType: "winter",
    description:
      "Cette nouvelle formule vous invite à venir passer la journée dehors avec nous !" +
      "Location de raquettes en avant-midi pour sillonner les sentiers ou parcourir la poudreuse hors piste." +
      "À midi, une soupe est servie dans notre yourte chauffée au poêle à bois histoire de reprendre des" +
      "forces.\nRandonnée d’une heure de traîneau à chiens en après-midi suite à quoi vous pourrez en apprendre" +
      "d'avantage sur l'univers du musher en servant, si vous le souhaitez, la soupe de viande aux chiens" +
      "après la randonnée.\n\n\n***Vous vous déplacer pour presque 3 heures d'activité avec tous ce qui entoure " +
      "votre sortie! Un séjour prolongé !",
    inclusions: "-Soupe-Repas\n" + "-Stationnement\n" + "-Matériel pour activité\n" + "-Accès\n",
    exclusions: "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "DUO",
    departureHour: "13h",
    duration: "1h",
    picUrl: "https://www.aventurequatresaisons.com/images/traineau2h.jpg"
  },
  {
    name: "Raquette Tractée",
    pricing: 109,
    activityType: "winter",
    description:
      "La randonnée tractée est un sport méconnu, mais rapidement apprécié des amoureux des chiens. Facilement adaptable selon les" +
      " humeurs du groupe, le chien relié à l'humain par un bungee aidera dans les montées ou vous donnera la motivation d'aller plus vite." +
      "Praticable en toute saison, la relation entre l'humain et le chien est approfondie tout au long de la marche par une grande interraction.",
    inclusions: "-Stationnement\n" + "-Matériel pour activité\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "SOLO",
    departureHour: "14h",
    duration: "1h",
    picUrl: "https://www.aventurequatresaisons.com/images/randonneetractee.jpg"
  }
];

export const yourteActivities: activities[] = [
  {
    name: "Nuitée en Yourte",
    pricing: 140,
    activityType: "yourte",
    description:
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Stationnement\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "entre 1 et 4",
    departureHour: "11h",
    duration: "1 nuit",
    picUrl: "https://www.aventurequatresaisons.com/images/canotcampingyourte.jpg"
  },
  {
    name: "2 Nuits en Yourte",
    pricing: 250,
    activityType: "yourte",
    description:
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Stationnement\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "entre 1 et 4",
    departureHour: "11h",
    duration: "2 nuits",
    picUrl: "https://www.aventurequatresaisons.com/images/yourteperchee.jpg"
  },
  {
    name: "3 Nuits en Yourte",
    pricing: 360,
    activityType: "yourte",
    description:
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Stationnement\n" + "-Accès\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Lunettes de ski\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    boatPlace: "entre 1 et 4",
    departureHour: "11h",
    duration: "3 nuits",
    picUrl: "https://www.aventurequatresaisons.com/images/yourtevoyageuse.jpg"
  }
];

// ***-------------------summer Packages-----------------------------------***
export const canoePackages: packages[] = [
  {
    tag: "13km",
    name: "descente de 13km en canot",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: " 10h, 11h, 13h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/canot15km.jpg"
  },
  {
    tag: "25km",
    name: "descente de 25km en canot",
    pricing: 69,
    activityType: "summer",
    description:
      "Vous n'en n'êtes pas à votre première descente! Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter d'un paysage grandiose dont le site du fer à cheval de sable qui vous comblera. <br /><br />Notez que cette descente de 25km est parfaite pour tous les gens qui souhaitent une aventure douce et paysible mais prêts à mettre la pagaie à l'effort. 25 km de beauté sauvage à ne pas manquer ! <br /><br />" +
      "Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture." +
      "La navette incluse se fait au début de l'activité." +
      "Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience." +
      "L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h, 10h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/canot25km.jpg"
  },
  {
    tag: "canotCamping25",
    name: "Canot-Camping de 25km",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre </p>",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h, 10h",
    duration: 2,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping.jpg"
  },
  {
    tag: "canotCamping32",
    name: "Canot-Camping de 32km",
    pricing: 139,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre </p>",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h",
    duration: 3,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping374j.jpg"
  }
];

export const kayakPackages: packages[] = [
  {
    tag: "13kmkayak",
    name: "descente de 13km en kayak",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["kayak"],
    departureHour: " 10h, 11h, 13h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/kayak15km.jpg"
  },
  {
    tag: "25kmkayak",
    name: "descente de 25km en kayak",
    pricing: 69,
    activityType: "summer",
    description:
      "Vous n'en n'êtes pas à votre première descente! Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter d'un paysage grandiose dont le site du fer à cheval de sable qui vous comblera. <br /><br />Notez que cette descente de 25km est parfaite pour tous les gens qui souhaitent une aventure douce et paysible mais prêts à mettre la pagaie à l'effort. 25 km de beauté sauvage à ne pas manquer ! <br /><br />" +
      "Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture." +
      "La navette incluse se fait au début de l'activité." +
      "Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience." +
      "L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["kayak"],
    departureHour: "9h, 10h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/kayak25km.jpg"
  },
  {
    tag: "canotCamping25kayak",
    name: "Kayak-Camping de 25km",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre </p>",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["kayak"],
    departureHour: "9h, 10h",
    duration: 2,
    picUrl:
      "https://scontent-yyz1-1.xx.fbcdn.net/v/t39.30808-6/288925813_5484029158294908_2560757275076730668_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=730e14&_nc_ohc=KdF8f6_irfoAX99F2Pe&_nc_ht=scontent-yyz1-1.xx&oh=00_AfATeuA9nEXTATsMoUZWgPGDtOLkeoP29DEmqN6fEdW1Qw&oe=64932A39"
  }
];

export const supPackages: packages[] = [
  {
    tag: "13kmsup",
    name: "13km en paddleboard",
    pricing: 49,
    activityType: "summer",
    description:
      "Si vous n'êtes que de passage dans la région, ce forfait est idéal pour vous ! \n" +
      "Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter allègrement de nos deux îles de sable blond. " +
      "\n\nNotez que cette descente de quinze kilomètres est parfaite pour tous les gens qui souhaitent une aventure douce et paysible. Avec ses paysages sauvages, la rivière-rouge vous comblera et vous inspirera longtemps après votre départ! Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture. " +
      "\n\nLa navette incluse se fait au début de l'activité. Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience. L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["sup"],
    departureHour: " 10h, 11h, 13h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/sup15km.jpg"
  },
  {
    tag: "25kmsup",
    name: "descente de 25km en sup",
    pricing: 69,
    activityType: "summer",
    description:
      "Vous n'en n'êtes pas à votre première descente! Cette descente en eau calme vous permettra de canoter paisiblement sur la Rivière Rouge et de profiter d'un paysage grandiose dont le site du fer à cheval de sable qui vous comblera. <br /><br />Notez que cette descente de 25km est parfaite pour tous les gens qui souhaitent une aventure douce et paysible mais prêts à mettre la pagaie à l'effort. 25 km de beauté sauvage à ne pas manquer ! <br /><br />" +
      "Prenez le temps que vous souhaitez pour descendre la rivière car vous terminerez à votre voiture." +
      "La navette incluse se fait au début de l'activité." +
      "Un bain de soleil ou une pause pique nique sur les nombreuses plages de sable blond agrémenteront l'expérience." +
      "L'activité parfaite pour profiter pleinement d'une belle journée d'été.",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["sup"],
    departureHour: "9h, 10h",
    duration: 1,
    picUrl: "https://www.aventurequatresaisons.com/images/sup25km.jpg"
  }
];

export const canotCampingPackages: packages[] = [
  {
    tag: "canotCamping25",
    name: "Canot-Camping de 25km",
    pricing: 98,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre </p>",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h, 10h",
    duration: 2,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping.jpg"
  },
  {
    tag: "canotCamping32",
    name: "Canot-Camping de 32km",
    pricing: 139,
    activityType: "summer",
    description:
      "Partez a l'aventure sur 25 km d'eau calme de rivière à bord de votre canot. Ce forfait permet de profiter davantage du site de campement avec moins de km a faire en canot." +
      "Muni de votre tente, installez-vous sur l'une des nombreuses plages magestueuses de la Rivière-Rouge." +
      "Connue pour ses dunes de sable blond, cette section de rivière vous offrira de nombreux paysages a couper le souffle tout au long de votre séjour. Le séjour inclu un petit rapide de classe R1 de 150 mètre </p>",
    inclusions:
      "-Embarcation\n" +
      "-VFI\n" +
      "-Paguais\n" +
      "-Kit de Sécurité\n" +
      "-Transport\n" +
      "-Accès\n" +
      "-Stationnement\n" +
      "-Carte de la rivière\n",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Crème Solaire\n" + "-Protecteur Téléphone\n",
    ageMinimum: 3,
    item: ["canoe"],
    departureHour: "9h",
    duration: 3,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcamping374j.jpg"
  }
];

// END-------------------summer Package-----------------------------------***

// ***-------------------winter Packages-----------------------------------***
export const sledPackages: packages[] = [
  {
    tag: "sled1H",
    name: "Une heure de traineau à chien",
    pricing: 139,
    activityType: "winter",
    description:
      "Le traineau a chiens, À 1h45 de Montréal !" +
      "La randonnée de une heures permet aux participants de conduire leur propre embarcation en alternance et d'aller voir les sentiers plus techniques." +
      "Accompagnés par un guide qui ouvre la route, les chiens sillonnent des sentiers boisés et le champ pour un parcours féérique." +
      "La neige alourdissant les branches des arbres fait un paysage incroyable digne de nos belles Laurentides." +
      "Les chiens motivés quant à eux apprécieront vos caresses à la fin de la randonnée.",
    inclusions: "-Guide" + "-Matériel" + "-Casque",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["sled"],
    departureHour: "9h, 11h, 13h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/traineau1h.jpg"
  }
];
export const sledVipPackages: packages[] = [
  {
    tag: "sledVIP",
    name: "Traineau à chien VIP pour 4 personnes",
    pricing: 269,
    activityType: "winter",
    description:
      "Cette nouvelle formule vous invite à venir passer la journée dehors avec nous !" +
      "Location de raquettes en avant-midi pour sillonner les sentiers ou parcourir la poudreuse hors piste." +
      "À midi, une soupe est servie dans notre yourte chauffée au poêle à bois histoire de reprendre des" +
      "forces." +
      "Randonnée d’une heure de traîneau à chiens en après-midi suite à quoi vous pourrez en apprendre" +
      "davantage sur l'univers du musher en servant, si vous le souhaitez, la soupe de viande aux chiens" +
      "après la randonnée.",
    inclusions: "-Guide" + "-Matériel" + "-Casque",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["sled", "VIP"],
    departureHour: "12h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/traineau2h.jpg"
  }
];
export const jorringPackages: packages[] = [
  {
    tag: "jorringBoreal",
    name: "Randonnée Tractée - La Boréale",
    pricing: 109,
    activityType: "winter",
    description:
      "La randonnée tractée est un sport méconnu, mais rapidement apprécié des amoureux des chiens." +
      "Facilement adaptable selon les humeurs du groupe, le chien relié à l'humain par un bungee aidera dans les montées ou vous donnera la motivation d'aller plus vite." +
      "Praticable en toute saison, la relation entre l'humain et le chien est approfondie tout au long de la marche par une grande interraction. ",
    inclusions: "-Guide" + "-Matériel",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["jorring"],
    departureHour: "14h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/randonneetractee.jpg"
  },
  {
    tag: "jorringSportive",
    name: "Randonnée Tractée - La Sportive",
    pricing: 199,
    activityType: "winter",
    description:
      "Pour une telle ballade, il faut être en forme! Disons-le. Mais si vous pouvez tenir les pas, vous aurai une journée mémorable !" +
      "La randonnée tractée est un sport méconnu, mais rapidement apprécié des amoureux des chiens." +
      "Facilement adaptable selon les humeurs du groupe, le chien relié à l'humain par un bungee aidera dans les montées ou vous donnera la motivation d'aller plus vite." +
      "Praticable en toute saison, la relation entre l'humain et le chien est approfondie tout au long de la marche par une grande interraction.",
    inclusions: "-Guide" + "-Matériel",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Vêtements adaptés multicouche\n" + "-Lunettes de ski\n",
    ageMinimum: 3,
    item: ["jorring"],
    departureHour: "14h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/randotractee2h.jpg"
  }
];
// END-------------------winter Package-----------------------------------***

// ***-------------------yourte Packages-----------------------------------***

export const yourteOneNight: packages[] = [
  {
    tag: "yourte1Night",
    name: "Séjour d'une nuitée en Yourte Mongole",
    pricing: 140,
    activityType: "winter",
    description:
      "Exotique séjour d'une nuitée en Yourte Mongole" +
      "Pour les amateurs de camping à la recherche d'un peu plus de glamour, la yourte de Mongolie offre son époustouflant exostisme le temps d'une nuit." +
      "Les lits complets déja installés permettent de profiter pleinement du site d'hébergement afin de créer vos propres souvenirs de moments magiques." +
      "Il ne reste que les repas a prévoir ! Quel cuisto se démarquera dans cette cuisine entourée d'arbres ?",
    inclusions: "-Table à pique-nique" + "-Plage, Accès à l'eau",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Effets personnel\n",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "12h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/canotcampingyourte.jpg"
  },
  {
    tag: "yourte2Night",
    name: "La yourte Voyageuse - 2 nuits",
    pricing: 250,
    activityType: "winter",
    description:
      "Amicalement appellée la 'Super Ger'. Ger voulant dire Yourte en Mongole, la super Ger est une super yourte!" +
      "Une yourte mongole complètement redimensionnée afin de se mariée à nos coutumes occidentales." +
      "La yourte Voyageuse est une belle histoire de voyage dans le temps et de traversée océanique." +
      "Elle nous proviens du passé...de la mongolie...Avec une touche américaine !" +
      "Toutes nos yourtes ont une réduction pour la deuxième nuitée !",
    inclusions: "-Table à pique-nique" + "-Plage, Accès à l'eau",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Effets personnel\n",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "12h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/yourtevoyageuse.jpg"
  },
  {
    tag: "yourte3Night",
    name: "La yourte Perchée - 3 nuits",
    pricing: 360,
    activityType: "winter",
    description:
      "Nul besoin de vous expliquer sont nom ! À l'abris de tout! la yourte perchée est une yourte de 21 pieds aux allures tipiques de la Mongolie. " +
      "C'est notre plus grande yourte en hébergement et elle est très apprécié pour sa proximité de la mise à l'eau." +
      "Toutes nos yourtes ont une réduction pour la deuxième et troisième nuitée !",
    inclusions: "-Table à pique-nique" + "-Plage, Accès à l'eau",
    exclusions: "-Nourriture\n" + "-Effets Personnel\n",
    stuffToBring: "-Effets personnel\n",
    ageMinimum: 3,
    item: ["yourte"],
    departureHour: "12h",
    duration: 0.1,
    picUrl: "https://www.aventurequatresaisons.com/images/yourteperchee.jpg"
  }
];

export const yourteTwoNight: packages[] = [];

export const yourteThreeNight: packages[] = [];

// END-------------------yourte Package-----------------------------------***
