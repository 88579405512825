import React from "react";

const ButtonBooking: React.FC = () => {
  return (
    <div className="flex flex-row flex-wrap justify-around lg:w-auto sm:w-[500px] hover:bg-aqs-blue-200 cursor-pointer border-2 border-aqs-blue-300 rounded">
      <div className="flex m-3 text-black text-2xl font-bold">
        <h6>Réserver</h6>
      </div>
    </div>
  );
};

export default ButtonBooking;
