import React from "react";
import { defaultContentText } from "./../helper/defaultDataContent";
import bgImage from "../../../src/images/bgtest.png";
import OpenBookingMenuButton from "./OpenBookingMenuButton";
import { v4 as uuidv4 } from "uuid";

const Description: React.FC = () => {
  return (
    <div className="bg-gradient-to-r to-aqs-green-400 from-aqs-blue-500 text-base rounded-b-2xl rounded-3xl border-[1px] border-white overflow-hidden">
      <div className=" bg-cover text-white" style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="flex flex-col items-center bg-aqs-blue-50/50">
          <div className="p-2 font-josefin text-2xl font-bold ">
            <p>{defaultContentText.mission.title}</p>
          </div>
          <div>
            <OpenBookingMenuButton key={uuidv4()} />
          </div>

          <div className=" text-2xl font-bold  ">
            <hr className="mx-[10%]" />
            <h1 className="p-2">{defaultContentText.mission.subtitle}</h1>
            <hr className="mx-[10%]" />
          </div>
          <div>
            <h1 className="p-4">{defaultContentText.mission.description}</h1>
          </div>
          <hr />
          <div className=" text-2xl font-bold ">
            <hr className="mx-[10%]" />
            <h1 className="p-2">{defaultContentText.mission.subtitleWinter}</h1>
            <hr className="mx-[10%]" />
          </div>
          <div className="p-4">
            <h1>{defaultContentText.mission.descriptionWinter}</h1>
          </div>
          <hr />
          <div>
            <OpenBookingMenuButton key={uuidv4()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Description;
